/*
 * IMPORTANT NOTICE:
 *
 * Use next options for local development:
 * Variant 1 - use Docker and production build:
 *   1. Be sure your environment installed: https://octonus-teams.com/wiki/display/CUT/Fresh+Cutwise+local+environment+installation
 *   2. Run "npm run build:prod"
 *   3. Open website via link https://cutwise.local/
 *   4. Go to "Dev. Console -> Application -> Service workers" and unregister worker with source "sw.js"
 *   5. Reload page
 *
 * Variant 2 - use webpack-dev-server:
 *   1. In webpack config disable option "webpackConfig.devtool" (you can simply comment these lines)
 *   2. In webpack config set option "webpackConfig.optimization.runtimeChunk" to "false" (boolean value)
 *   3. Run "npm run serve"
 *   4. Open website via link https://cutwise.local:9009/ (or another address if you have changed host and port for webpack-dev-server)
 *   5. Go to "Dev. Console -> Application -> Service workers" and unregister worker with source "sw.js"
 *   6. Reload page
 *
 * Keep in mind: this variant doesn't work with office netbox.
 *
 * When you are done with code - revert changes in webpack config!!!
 * */

import {NetboxStatusEvent} from '@/netbox/constants/events';
import {NetboxStatus} from '@/netbox/constants/statuses';
import { convertToNetboxURL, shouldConvertToNetbox } from '@/netbox/helpers/netbox';

function newRequest(request) {
  return new Request(convertToNetboxURL(request.url), {
    method: request.method,
    referrer: request.referrer,
    referrerPolicy: request.referrerPolicy,
    redirect: request.redirect,
    integrity: request.integrity,
    // headers: request.headers, // disable on ios
    // credentials: request.credentials, // disable on ios
    // cache: request.cache, // disable on ios
  });
}

let isNetBoxActive = false;
// eslint-disable-next-line no-unused-vars
let cacheStorage = null;

self.addEventListener('install', () => {
  console.log('Service Worker: Installing...');

  // The following causes a newly installed service worker to progress into the activating state, regardless of whether there is already an active service worker.
  self.skipWaiting();
});

self.addEventListener('activate', (event) => {
  console.log('Service Worker: Activating...');

  event.waitUntil(caches.open("cutwise-offline").then((cache) => {
    console.log("Service Worker: Caching index and important routes");

    return cache.addAll([
      "/",
      "https://files-cdn.cutwise.com/player/develop/player.min.js",
      "static/offline.json",
      "static/common.svg"
    ]);
  }));
});

self.addEventListener('fetch', (event) => {
  if (isNetBoxActive && shouldConvertToNetbox(event.request.url)) {
    event.respondWith(fetch(newRequest(event.request)).then(response => response));
  } else if (event.request.url !== 'https://box.cutwise.link/') {
    event.respondWith(checkResponse(event.request).catch(() => returnFromCache(event.request)));
    event.waitUntil(addToCache(event.request));
  }
});

self.addEventListener('message', (event) => {
  if (event.data.code !== NetboxStatusEvent.Send) {
    return;
  }

  isNetBoxActive = event.data.status === NetboxStatus.Active;
  caches.open('cutwise').then(cache => cacheStorage = cache);
});

function checkResponse(request){
  return new Promise((fulfill, reject) => {
    fetch(request).then((response) => {
      if(response.status !== 404) {
        fulfill(response);
      } else {
        reject();
      }
    }, reject).catch(reject);
  });
}

function addToCache(request){
  return caches.open("cutwise-offline").then((cache) => {
    return fetch(request).then((response) => {
      console.log('Service Worker: ' + response.url + ' was cached', response);
      return cache.put(request, response);
    }).catch(() => {
      // do nothing
    });
  });
}

function returnFromCache(request){
  return caches.open("cutwise-offline").then((cache) => {
    return cache.match(request).then((matching) => {
      if(!matching || matching.status == 404) {
        if (request.url.includes('common.svg')) {
          return cache.match("static/common.svg");
        }

        return cache.match("static/offline.json");
      } else {
        return matching;
      }
    });
  });
}
